<template>
  <!-- TODO: need real data -->
  <article class="casino-container">
    <div class="info-container">
      <div class="main-information">
        <img
          :alt="casino.logoAlt"
          :src="getContentfulImage(casino.logo, 'casino')"
          class="avatar-img"
          height="40"
          width="40"
        >
        <p v-if="casino.title" class="casino-name">
          {{ casino.title }}
        </p>
      </div>
      <div v-if="casino.rate" aria-label="Rate" class="total-rate">
        <div>
          <span class="amount" :style="{ color: getRatingColor(casino.rate) }">
            {{ casino.rate }}</span>/<span class="of">5</span>
        </div>
        <BRIcon
          :height="$vuetify.breakpoint.xs ? '30' : '34'"
          :width="$vuetify.breakpoint.xs ? '26' : '30'"
          class="icon-rating"
          icon="rating"
          :style="{ color: getRatingColor(casino.rate) }"
        />
      </div>
    </div>
    <div v-if="casino.description" class="casino-description">
      {{ casino.description }}
    </div>
    <div class="divider" />
    <div v-if="casino.bestBonus" class="bonus">
      <BRIcon
        class="icon"
        icon="bonus"
        themed
      />
      <div class="bonus-title">
        {{ casino.bestBonus }}
      </div>
    </div>
    <ul v-if="casino.bonuses && casino.bonuses.length" aria-label="Bonuses" class="card-bonuses">
      <li v-for="(bonus, index) in casino.bonuses" :key="index" class="card-bonus-item">
        <div aria-hidden="true" class="hexagon-mark" />
        <span class="text">{{ bonus.fields.title }}</span>
      </li>
    </ul>

    <div class="read-more">
      <BRButton
        :height="$vuetify.breakpoint.xs ? '35' : '45'"
        dark
        width="100%"
        @click="redirectToItem"
      >
        READ MORE
      </BRButton>
    </div>
  </article>
</template>

<script>
import images from '@/mixins/image'
import ratingColor from '@/mixins/ratingColor'

export default {
  name: 'CasinoVerticalCard',
  mixins: [images, ratingColor],
  props: {
    casino: {
      type: Object,
      required: true
    }
  },
  methods: {
    redirectToItem() {
      this.$router.push({
        name: 'CasinoItemPage',
        params: { id: this.casino.id }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/style/mixins.scss";

.casino-container {
  @include content;

  align-items: flex-start;
  grid-gap: 18px 44px;
  grid-template-columns: 1fr auto;
}

.info-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 18px;

  @include breakpoint(medium) {
    justify-content: center;
    margin-bottom: 16px;
  }
}

.main-information {
  display: flex;
  align-items: center;
  gap: 12px;

  @include breakpoint(medium) {
    flex-direction: column;
    gap: 20px;
  }
}

.avatar-img {
  border-radius: 50%;
  object-fit: cover;

  @include breakpoint(medium) {
    width: 100px;
    height: 100px;
  }
}

.casino-description {
  @include subtitle;

  display: none;
  overflow: hidden;
  margin-bottom: 14px;
  text-align: center;
  text-overflow: ellipsis;
  /* stylelint-disable-next-line property-no-vendor-prefix */
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;

  @include breakpoint(medium) {
    display: block;
  }
}

.casino-name {
  font-weight: var(--font-weight-bold);
  font-size: var(--font-h3);
}

.total-rate {
  display: flex;
  align-items: center;
  min-width: 60px;
  gap: 8px;

  .amount {
    font-weight: var(--font-weight-bold);
    font-size: var(--font-h2);
    color: var(--primary-text-color);
  }

  .of {
    font-size: var(--font-base);
  }

  @include breakpoint(medium) {
    display: none;
  }
}

.icon-rating {
  color: var(--primary-icon-color);
}

.card-bonuses {
  display: grid;
  margin-bottom: 20px;
  padding: 0;
  gap: 10px;

  .text {
    @include subtitle;
  }

  @include breakpoint(medium) {
    display: none;
  }
}

.card-bonus-item {
  display: flex;
  align-items: flex-start;
  gap: 8px;
}

.hexagon-mark {
  position: relative;
  top: 3px;
  width: 10px;
  height: 6px;
  background-color: var(--primary-icon-color);
}

.hexagon-mark::before {
  content: "";
  position: absolute;
  top: -3px;
  border-right: 5px solid transparent;
  border-bottom: 3px solid var(--primary-icon-color);
  border-left: 5px solid transparent;
}

.hexagon-mark::after {
  content: "";
  position: absolute;
  bottom: -3px;
  border-top: 3px solid var(--primary-icon-color);
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
}

.divider {
  display: none;
  margin: 12px 0 18px;
  height: 1px;
  background-color: var(--secondary-bg-color);
  opacity: 0.2;

  @include breakpoint(medium) {
    display: block;
  }
}

.bonus {
  display: none;
  align-items: flex-start;
  justify-content: center;
  margin-bottom: 18px;
  padding: 0 15px;

  .icon {
    display: block;
    margin-right: 13px;
    min-width: 16px;
    min-height: 16px;
  }

  .bonus-title {
    font-size: var(--font-base);
  }

  @include breakpoint(medium) {
    display: flex;
  }
}
</style>
