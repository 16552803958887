<template>
  <section class="reviews">
    <CasinoReviewsTotalRating v-if="casino.totalRating" :casino="casino" />
    <CasinoReviewsUserReviews :casino="casino" @updateComments="$emit('updateComments')" />
    <div class="claim-bonus-btn-container">
      <BRButton
        v-if="casino.claimBonusLink"
        :height="$vuetify.breakpoint.xs ? '40' : '45'"
        :width="$vuetify.breakpoint.xs ? '100%' : '150'"
        dark
        :href="casino.claimBonusLink"
      >
        CLAIM BONUS
      </BRButton>
    </div>
  </section>
</template>

<script>
import CasinoReviewsTotalRating from '@/components/CasinoReviewsTotalRating.vue'
import CasinoReviewsUserReviews from '@/components/CasinoReviewsUserReviews.vue'

export default {
  name: 'CasinoReviews',
  components: {
    CasinoReviewsTotalRating,
    CasinoReviewsUserReviews
  },
  props: {
    casino: {
      type: Object,
      required: false,
      default: null
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/style/mixins.scss";

.reviews {
  @include container;

  display: grid;
  gap: 20px;
}

.claim-bonus-btn-container {
  @include breakpoint(medium) {
    display: flex;
    justify-content: flex-end;
  }
}
</style>
