<template>
  <BRCardContainer class="casino-reviews-total-rating">
    <div class="total-user-rate-container">
      <h4 class="header">
        Total user rating
      </h4>
      <div class="total-user-rate">
        <div aria-label="Rate" class="total-rate-value">
          <BRRatingHexagon :current-rating="casino.totalRating" read-only />
          <div>
            <span
              class="amount"
              :style="{ color: getRatingColor(casino.totalRating) }"
            >{{ casino.totalRating }}</span>/<span class="of">5</span>
          </div>
        </div>
        <p v-if="casino.userReviewsAmount" class="add-info">
          <span
            class="key-info"
            :style="{ color: getRatingColor(casino.totalRating) }"
          >
            {{ casino.totalRating }}
          </span> stars rating from <span
            class="key-info"
            :style="{ color: getRatingColor(casino.totalRating) }"
          >{{ casino.userReviewsAmount }}</span> user reviews
        </p>
      </div>
    </div>
    <div class="progress-user-rate">
      <h4 class="header">
        Blockreviews rating
      </h4>
      <ul class="progress-rating-list">
        <li
          v-for="rating in ratings"
          :key="rating.field"
        >
          <BRRatingProgress
            :progress="casino[rating.field]"
          >
            {{ rating.title }}
          </BRRatingProgress>
        </li>
      </ul>
    </div>
  </BRCardContainer>
</template>

<script>
import ratingColor from '@/mixins/ratingColor'
import BRRatingHexagon from '@/components/BRComponents/BRRatingHexagon.vue'
import BRRatingProgress from '@/components/BRComponents/BRRatingProgress'
import BRCardContainer from '@/components/BRComponents/BRCardContainer'

export default {
  name: 'CasinoReviewsTotalRating',
  components: {
    BRRatingHexagon,
    BRRatingProgress,
    BRCardContainer
  },
  mixins: [ratingColor],
  props: {
    casino: {
      type: Object,
      required: false,
      default: () => {
      }
    }
  },
  data() {
    return {
      ratings: [
        { title: 'User friendly', field: 'userFriendly' },
        { title: 'Bonuses', field: 'bonusesRating' },
        { title: 'Payment methods & Simplicity', field: 'paymentMethodsSimplicity' },
        { title: 'Customer support', field: 'customerSupportRating' }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/style/mixins.scss";

.casino-reviews-total-rating {
  @include content;

  padding: 20px 32px 24px;
  height: 100%;
  box-shadow: var(--tertiary-box-shadow);

  .header {
    margin-bottom: 20px;
    font-size: var(--font-h4);
  }

  .add-info {
    margin: 0;
    font-size: var(--font-base);
  }

  .key-info {
    font-weight: var(--font-weight-bold);
    font-size: var(--font-base);
    color: var(--primary-text-color);
  }

  @include breakpoint(medium) {
    display: flex;
    padding: 20px 25px 34px;
    box-shadow: none;
  }
}

.total-user-rate-container {
  &::after {
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    width: 1px;
    height: 100%;
    background-color: var(--secondary-bg-color);
    opacity: 0.2;
  }

  .total-user-rate {
    margin-bottom: 26px;
    color: var(--secondary-text-color);

    @include breakpoint(medium) {
      margin-bottom: 0;
    }
  }

  @include breakpoint(medium) {
    position: relative;
    padding-right: 36px;
    min-width: 286px;
  }
}

.total-rate-value {
  display: flex;
  align-items: center;
  grid-area: rate;
  margin-bottom: 14px;
  min-width: 60px;
  gap: 20px;

  .amount {
    font-weight: var(--font-weight-bold);
    font-size: var(--font-h1);
    color: var(--primary-text-color);
  }

  .of {
    font-size: var(--font-h3);
  }

  @include breakpoint(medium) {
    gap: 26px;
  }
}

.total-rate-value ::v-deep svg {
  @include breakpoint(medium) {
    min-width: 100%;
  }
}

.progress-user-rate {
  .progress-rating-list {
    display: grid;
    padding: 0;
    text-transform: uppercase;
    gap: 20px;

    &::after {
      @include breakpoint(medium) {
        content: "";
        position: absolute;
        left: 50%;
        width: 1px;
        height: 100%;
        background-color: var(--secondary-bg-color);
        opacity: 0.2;
      }
    }

    ::v-deep .text {
      @include breakpoint(medium) {
        color: var(--secondary-text-color);
      }
    }

    @include breakpoint(medium) {
      position: relative;
      column-gap: 88px;
      grid-template-columns: repeat(2, 1fr);
    }
  }

  @include breakpoint(medium) {
    padding-left: 36px;
    width: 100%;
  }
}

</style>
