<template>
  <section class="casino">
    <template v-if="casino">
      <CasinoHeader
        :casino="casino"
        :is-bookmarked="isBookmarked"
        class="casino-header"
        @updateBookmark="updateBookmark"
      />
      <section class="tabs-header">
        <BRTabs
          :tabs="tabs"
          :active-tab="currentTab"
          class="tabs"
          tab-width="190"
          @changeActiveTab="changeActiveTab"
        />
      </section>
      <component
        :is="currentTab"
        :casino="casino"
        @updateComments="fetchCasino"
      />
    </template>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import api from '@/api'
import { fetchCasinoRatings } from '@/services/fetchReviewsRatings'
import calculateRating from '@/services/calculateRating'
import BRTabs from '@/components/BRComponents/BRTabs.vue'
import CasinoHeader from '@/components/CasinoHeader'
import CasinoOverview from '@/components/CasinoOverview'
import CasinoBonuses from '@/components/CasinoBonuses'
import CasinoReviews from '@/components/CasinoReviews'
import CasinoAlternatives from '@/components/CasinoAlternatives'

export default {
  name: 'CasinoItemPage',
  components: {
    BRTabs,
    CasinoHeader,
    CasinoOverview,
    CasinoBonuses,
    CasinoReviews,
    CasinoAlternatives
  },
  data() {
    return {
      comments: [],
      alternatives: [],
      tabs: [
        {
          id: 1,
          title: 'overview',
          icon: 'eye',
          name: 'CasinoOverview'
        },
        {
          id: 3,
          title: 'bonuses',
          icon: 'bonus',
          name: 'CasinoBonuses'
        },
        {
          id: 4,
          title: 'reviews',
          icon: 'chat',
          name: 'CasinoReviews'
        },
        {
          id: 5,
          title: 'alternatives',
          icon: 'search',
          name: 'CasinoAlternatives'
        }
      ],
      currentTab: 'CasinoOverview',
      loadCasinoCommentsParams: {
        sortBy: 'recent',
        pageNumber: 0,
        pageSize: 10
      },
      isBookmarked: false,
      casino: {}
    }
  },
  computed: {
    ...mapGetters(['getUserDB'])
  },
  mounted() {
    this.fetchCasino()
    this.fetchBookmarkInfo()
  },
  methods: {
    ...mapActions(['setIsNotFound']),
    async fetchBookmarkInfo() {
      const { data } = await api.likes.getLikes(this.casino.id)
      if (!data || !data.length) return
      this.isBookmarked = data[0].isLiked
    },
    updateBookmark(value) {
      this.isBookmarked = value.data
    },
    changeActiveTab(componentName) {
      this.currentTab = componentName
    },
    async fetchCasino() {
      const contentfulCasinoIndex = this.$contentful.reviews.findIndex(
        (item) => item.id === this.$route.params.id
      )

      const data = await fetchCasinoRatings(this.$route.params.id)

      if (data.length) {
        const totalRating = calculateRating(
          data[0].userReviews,
          this.$contentful.reviews[contentfulCasinoIndex].contentfulRating
        )

        this.$contentful.reviews[contentfulCasinoIndex] = {
          ...this.$contentful.reviews[contentfulCasinoIndex],
          ...data[0],
          totalRating
        }
      }

      this.casino = this.$contentful.reviews[contentfulCasinoIndex]
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/style/mixins.scss";

.casino {
  @include container;
}

.casino-header {
  margin-bottom: 20px;

  @include breakpoint(medium) {
    margin-bottom: 44px;
  }
}

.tabs-header {
  margin-bottom: 10px;
  border-radius: var(--border-radius-default);
  box-shadow: var(--secondary-box-shadow);

  @include breakpoint(medium) {
    margin-bottom: 14px;
  }
}

.tabs ::v-deep .icon {
  display: none;

  @include breakpoint(medium) {
    display: block;
  }
}
</style>
