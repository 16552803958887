<template>
  <div class="rich-text-renderer">
    <RichTextRenderer :document="document" />
  </div>
</template>

<script>
import RichTextRenderer from 'contentful-rich-text-vue-renderer'

export default {
  name: 'BRRichTextRenderer',
  components: {
    RichTextRenderer
  },
  props: {
    document: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.rich-text-renderer {
  p {
    margin-top: 1em;
    margin-bottom: 1em;
    font-size: var(--font-base);

    &:empty {
      display: none;
    }

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  h1 {
    font-size: var(--font-h1);
  }

  h2 {
    font-size: var(--font-h2);
  }

  h3 {
    font-size: var(--font-h3);
  }

  h4 {
    font-size: var(--font-h4);
  }

  h5 {
    font-size: var(--font-h5);
  }

  ul {
    list-style: revert;
    padding-left: 24px;
  }
}
</style>
