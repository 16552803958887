const validationRules = {
  data() {
    return {
      rules: {
        required: (value) => !!value || 'This field is required'
      }
    }
  }
}

export default validationRules
