<template>
  <div>
    <BRCardContainer class="casino-reviews-user-reviews card">
      <div class="headers-actions-container">
        <div class="headers">
          <h4 class="review-title">
            User reviews
          </h4>
          <p class="reviews-subtitle">
            Here you can read what other people think about this casino or you can leave your own comment!
          </p>
        </div>
        <div class="actions">
          <CasinoReviewsAddUserReview
            v-if="!casino.hasOwnProperty('canAddComment') || casino.canAddComment"
            :casino="casino"
            @updateComments="resetUserComments"
          />
          <v-menu
            content-class="sort-reviews-menu"
            bottom
            :nudge-bottom="$vuetify.breakpoint.xs ? '35' : '45'"
          >
            <template #activator="{ on }">
              <BRButton
                :height="$vuetify.breakpoint.xs ? '35' : '45'"
                :width="$vuetify.breakpoint.xs ? '100%' : '210'"
                class="sort-reviews-button"
                color="var(--secondary-button-bg-color)"
                dark
                :on="on"
              >
                SORT BY
                <v-icon class="arrow-down-icon">
                  mdi-menu-down
                </v-icon>
              </BRButton>
            </template>

            <ul class="sort-list">
              <li
                v-for="sort in commentsSort"
                :key="sort.id"
                :class="{ 'active': sort.id === activeSort }"
                class="sort-list-item"
                @click="changeCommentsSort(sort)"
              >
                {{ sort.title }}
              </li>
            </ul>
          </v-menu>
        </div>
      </div>
      <ul class="reviews-list">
        <template v-if="reviewsList.length">
          <li v-for="comment in reviewsList" :key="comment.id">
            <BRActionsComments
              :casino="casino"
              :data-review="comment"
              @editComment="editComment"
              @updateComments="resetUserComments"
            />
          </li>
        </template>
        <template v-else-if="!loading">
          <p class="no-reviews">
            No reviews found
          </p>
        </template>
        <v-progress-circular
          v-if="loading"
          class="loader-main"
          indeterminate
          size="50"
          width="5"
        />
      </ul>
      <div v-if="!loading" class="btn-container">
        <button
          v-if="isCanShowMore"
          class="show-more-button"
          @click="loadMore"
        >
          Show More
        </button>
      </div>
    </BRCardContainer>
    <UserCommentForm
      :casino-id="casino.id"
      :comment="selectedComment"
      :is-show-comment-dialog.sync="isShowCommentDialog"
      :user="getUserDB"
      @updateComments="resetUserComments"
    />
  </div>
</template>

<script>
import BRCardContainer from '@/components/BRComponents/BRCardContainer'
import BRActionsComments from '@/components/BRComponents/BRActionsComments.vue'
import CasinoReviewsAddUserReview from '@/components/CasinoReviewsAddUserReview.vue'
import UserCommentForm from '@/components/UserCommentForm'
import api from '@/api'
import { mapGetters } from 'vuex'

export default {
  name: 'CasinoReviewsUserReviews',
  components: {
    BRCardContainer,
    BRActionsComments,
    CasinoReviewsAddUserReview,
    UserCommentForm
  },
  props: {
    casino: {
      type: Object,
      required: false,
      default: null
    }
  },
  data() {
    return {
      activeSort: 1,
      loading: true,
      isCanShowMore: false,
      selectedComment: {},
      isShowCommentDialog: false,
      commentsSort: [
        {
          id: 1,
          title: 'The most helpful',
          sort: 'most-helpful'
        },
        {
          id: 2,
          title: 'Newest',
          sort: 'newest'
        },
        {
          id: 3,
          title: 'Oldest',
          sort: 'oldest'
        }
      ],
      loadParams: {
        pageNumber: 0,
        pageSize: 7,
        sortBy: 'most-helpful'
      },
      reviewsList: []
    }
  },
  computed: {
    ...mapGetters(['getUserDB'])
  },
  mounted() {
    this.fetchComments()
  },
  methods: {
    async fetchComments() {
      this.loading = true
      const {
        data,
        error
      } = await api.comments.getItemComments(this.$route.params.id, this.loadParams)

      if (error) return

      this.reviewsList = this.reviewsList.concat(data)

      this.loading = false
      this.isCanShowMore = data.length === this.loadParams.pageSize
    },
    loadMore() {
      this.loadParams.pageNumber += 1
      this.fetchComments()
    },
    resetUserComments() {
      this.reviewsList = []
      this.loadParams.pageNumber = 0
      this.fetchComments()
      this.$emit('updateComments')
    },
    editComment(comment) {
      this.selectedComment = comment
      this.isShowCommentDialog = true
    },
    changeCommentsSort(sort) {
      this.activeSort = sort.id
      this.loadParams.sortBy = sort.sort
      this.resetUserComments()
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/style/mixins.scss";

.casino-reviews-user-reviews.wrap {
  padding: 20px;
  box-shadow: var(--tertiary-box-shadow);

  @include breakpoint(medium) {
    box-shadow: none;
  }
}

.headers-actions-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @include breakpoint(medium) {
    flex-direction: row;
  }
}

.headers {
  width: 100%;
  max-width: 400px;

  .review-title {
    margin-bottom: 20px;
    font-size: var(--font-h4);
  }

  .reviews-subtitle {
    @include subtitle;

    margin-bottom: 20px;

    @include breakpoint(medium) {
      margin-bottom: 30px;
    }
  }
}

.actions {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-bottom: 20px;
  gap: 14px;

  .sort-reviews-button {
    .arrow-down-icon {
      margin-left: 5px;
      max-width: 8px;
    }
  }

  ::v-deep .button-text {
    text-transform: uppercase;
  }

  @include breakpoint(medium) {
    flex-direction: row;
    min-width: 170px;
  }
}

.reviews-list {
  display: grid;
  margin-bottom: 20px;
  gap: 12px;

  @include breakpoint(medium) {
    gap: 16px;
  }
}

.btn-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;

  .show-more-button {
    font-weight: var(--font-weight-bold);
    font-size: var(--font-base);
    text-transform: uppercase;
    color: var(--secondary-text-color);
  }
}

.sort-reviews-menu.v-menu__content {
  border-radius: var(--border-radius-small);
  box-shadow: var(--box-shadow-default);
}

.sort-list {
  padding: 8px;
  background-color: var(--content-bg-color);

  .sort-list-item {
    border-radius: var(--border-radius-small);
    padding: 10px 16px;
    font-size: var(--font-base);
    text-align: center;
    text-transform: uppercase;
    color: var(--secondary-text-color);
    cursor: pointer;
  }

  .active {
    font-weight: var(--font-weight-bold);
    color: var(--primary-text-color);
    background-color: var(--primary-bg-color);
  }
}

.no-reviews {
  @include container;

  display: flex;
  align-items: center;
  height: 200px;
  text-align: center;
}
</style>
