<template>
  <div>
    <BRButton
      :height="$vuetify.breakpoint.xs ? '35' : '45'"
      :width="$vuetify.breakpoint.xs ? '100%' : '170'"
      class="add-review-btn"
      color="var(--tertiary-button-bg-color)"
      dark
      @click="openModal"
    >
      Add review
    </BRButton>
    <BRModal
      :is-dialog-open="isShowModal"
      content-class="add-review-modal"
      modal-width="800"
      @close="closeModal"
    >
      <template #title>
        Add your review
      </template>
      <template #description>
        <v-form v-model="isFormValid">
          <div class="main-info-modal">
            <div aria-label="Rate" class="total-rate desktop">
              <BRRatingHexagon
                :current-rating.sync="rating"
                class="hexagon-rating"
                height="52"
                width="46"
                @changeRatingValue="setRate"
              />
              <div>
                <span
                  class="amount"
                  :style="{ color: getRatingColor(rating) }"
                >{{ rating }}</span>/<span class="of">5</span>
              </div>
            </div>
            <div class="casino-info">
              <img
                :height="$vuetify.breakpoint.xs ? '50': '60'"
                :src="getContentfulImage(casino.logo, 'casino')"
                :width="$vuetify.breakpoint.xs ? '50': '60'"
                alt="Casino image"
                class="casino-image"
              >

              <div>
                <a :href="casino.website" class="casino-link rich-text">
                  {{ casino.website }}
                </a>
                <h3 class="casino-title">
                  <span class="rich-text">
                    {{ casino.name }}
                  </span>
                </h3>
                <p v-if="casino.userReviews" class="add-info">
                  <span class="key-info" :style="{ color: getTotalRatingColor }">
                    {{ casino.totalRating }}
                  </span> stars rating from
                  <span class="key-info" :style="{ color: getTotalRatingColor }">
                    {{ casino.userReviews }}
                  </span> user
                  reviews
                </p>
              </div>
            </div>
            <div aria-label="Rate" class="total-rate mobile">
              <BRRatingHexagon
                :current-rating.sync="rating"
                @changeRatingValue="setRate"
              />
              <div>
                <span
                  class="amount"
                  :style="{ color: getRatingColor(rating) }"
                >{{ rating }}</span>/<span class="of">5</span>
              </div>
            </div>
          </div>
          <div class="comment-container">
            <h4 class="comment-title">
              Tell us about your experience with <span class="casino-name">{{ casino.name }}</span> <span
                class="optional-text"
              >(optional)</span>
            </h4>
            <VueEditor
              ref="editor"
              v-model="comment"
              :editor-toolbar="toolbarConfig"
              class="editor"
              @text-change="checkTextSizeLimit"
            />
            <p :class="['comment', {'comment_error' : showCommentError} ]">
              Maximum 2000 characters
            </p>
          </div>
          <v-checkbox
            v-model="isTermsChecked"
            :rules="[rules.required]"
            class="checkbox"
            color="var(--copyrights-color)"
            hide-details
            name="terms"
            off-icon="mdi-checkbox-blank-outline"
            on-icon="mdi-checkbox-marked-circle-outline"
          >
            <template #label>
              <p class="terms">
                You agree with
                <router-link class="terms-link" target="_blank" to="">
                  OUR RULES OF REVIEWS
                </router-link>
                . consectetur adipiscing elit. Imperdiet nec dictum gravida ornare
                nam egestas nunc. Tortor urna, id nunc quis metus eget integer. Egestas urna amet semper orci nec,
                elementum mauris, mattis.
              </p>
            </template>
          </v-checkbox>
          <div class="actions">
            <BRButton
              :height="$vuetify.breakpoint.xs ? '40' : '45'"
              :width="$vuetify.breakpoint.xs ? '100%' : '150'"
              class="cancel-review-btn"
              color="var(--secondary-bg-color)"
              outlined
              type="button"
              @click="closeModal"
            >
              CANCEL
            </BRButton>
            <BRButton
              :disabled="!isFormValid || !rating || showCommentError"
              :height="$vuetify.breakpoint.xs ? '40' : '45'"
              :width="$vuetify.breakpoint.xs ? '100%' : '150'"
              class="send-review-btn"
              dark
              @click="sendReview"
            >
              ADD REVIEW
            </BRButton>
          </div>
        </v-form>
      </template>
    </BRModal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import BRModal from '@/components/BRComponents/BRModal.vue'
import BRRatingHexagon from '@/components/BRComponents/BRRatingHexagon.vue'
import images from '@/mixins/image'
import ratingColor from '@/mixins/ratingColor'
import validationRules from '@/mixins/validation'
import api from '@/api'
import vue2EditorConfig from '@/configs/vue2EditorConfig'
import { VueEditor } from 'vue2-editor'

export default {
  name: 'CasinoReviewsAddUserReview',
  components: {
    BRModal,
    BRRatingHexagon,
    VueEditor
  },
  mixins: [images, validationRules, ratingColor],
  props: {
    casino: {
      type: Object,
      required: false,
      default: null
    }
  },
  data() {
    return {
      isShowModal: false,
      comment: '',
      isTermsChecked: false,
      rating: 0,
      isFormValid: false,
      toolbarConfig: vue2EditorConfig,
      showCommentError: false
    }
  },
  computed: {
    ...mapGetters(['getUserDB']),
    getTotalRatingColor() {
      return `var(--${Math.round(this.casino.totalRating)}-star-rating-color)`
    }
  },
  methods: {
    openModal() {
      if (!this.getUserDB) {
        this.$router.push({
          name: 'Login',
          query: { redirect: this.$route.path }
        })
        return
      }
      this.isShowModal = true
    },
    closeModal() {
      this.isShowModal = false
    },
    async sendReview() {
      const { error } = await api.comments.addUserComment(this.casino.id, {
        parentId: 0,
        text: this.$sanitize(this.comment),
        rating: this.rating,
        id: this.getUserDB.id
      })

      if (!error) {
        this.closeModal()
        this.$emit('updateComments')
        this.comment = ''
        this.isTermsChecked = false
        this.rating = 0
      }
    },
    setRate(value) {
      this.rating = value
    },
    checkTextSizeLimit() {
      const textLength = this.$refs.editor.quill.getLength()

      this.showCommentError = textLength >= 2000
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/style/mixins.scss";

.add-review-btn {
  text-transform: uppercase;
}

.actions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.main-info-modal {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  padding-bottom: 22px;
  gap: 20px;

  &::after {
    content: "";
    position: absolute;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 1px;
    background-color: var(--secondary-bg-color);
    opacity: 0.2;
  }

  .total-rate {
    position: relative;
    display: flex;
    align-items: center;
    margin: 0;
    min-width: 60px;
    gap: 22px;

    &::after {
      content: "";
      position: absolute;
      right: 0;
      top: 0;
      display: none;
      width: 1px;
      height: 100%;
      background-color: var(--secondary-bg-color);
      opacity: 0.2;

      @include breakpoint(medium) {
        display: block;
      }
    }

    .amount {
      font-weight: var(--font-weight-bold);
      font-size: var(--font-h1);
      color: var(--primary-text-color);
    }

    .of {
      font-size: var(--font-h3);
    }

    &.desktop {
      display: none;

      .hexagon-rating::v-deep .item {
        width: 46px;
        height: 52px;
      }

      @include breakpoint(medium) {
        display: flex;
      }
    }

    &.mobile {
      display: flex;

      @include breakpoint(medium) {
        display: none;
      }
    }

    @include breakpoint(medium) {
      padding-right: 30px;
      padding-bottom: 30px;
      gap: 26px;
    }
  }

  .casino-title {
    margin-bottom: 8px;
    font-size: var(--font-h3);
    color: var(--text-color);
  }

  .casino-link {
    margin-bottom: 8px;
    font-weight: var(--font-weight-bold);
    font-size: var(--font-base);
    line-height: 11px;
    color: var(--link-color);
  }

  .casino-info {
    display: flex;
    align-items: center;
    gap: 18px;

    @include breakpoint(medium) {
      align-items: flex-start;
      padding-bottom: 30px;
      padding-left: 28px;
    }
  }

  .casino-image {
    border-radius: 50%;
    object-fit: cover;
  }

  @include breakpoint(medium) {
    flex-direction: row;
    margin-bottom: 30px;
    padding-bottom: 0;
    gap: 0;
  }
}

.add-info {
  margin: 0;
  font-size: var(--font-base);
}

.key-info {
  font-weight: var(--font-weight-bold);
  font-size: var(--font-base);
  color: var(--primary-text-color);
}

.comment-container {
  margin-bottom: 30px;

  .comment-title {
    margin-bottom: 16px;
    font-size: var(--font-base);
    color: var(--text-color);
  }

  .casino-name {
    color: var(--link-color);
  }

  .optional-text {
    color: var(--copyrights-color);
  }
}

.checkbox {
  margin: 0 0 28px;

  ::v-deep .v-input--selection-controls__input {
    align-self: flex-start;
    margin-right: 16px;
    border-radius: var(--border-radius-small);
    padding: 4px;
    width: 20px;
    height: 20px;
    background-color: var(--tertiary-bg-color);

    .mdi-checkbox-marked-circle-outline {
      font-size: var(--font-base);
      color: var(--content-bg-color) !important;
    }

    .mdi-checkbox-blank-outline {
      font-size: var(--font-base);
      color: transparent !important;
      caret-color: transparent !important;
    }
  }

  &::v-deep.v-input--is-label-active .v-input--selection-controls__input {
    align-self: flex-start;
    background-color: var(--main-bg-color);
  }

  ::v-deep .v-input--selection-controls__ripple {
    display: none;
  }

  ::v-deep .v-input__slot {
    align-items: flex-start;

    @include breakpoint(medium) {
      align-items: center;
    }
  }

  @include breakpoint(medium) {
    margin: 0 0 36px;
  }
}

.terms {
  margin-bottom: 0;
  font-size: var(--font-sm);
  color: var(--secondary-text-color);

  &-link {
    font-size: inherit;
    color: var(--primary-text-color);
  }
}

::v-deep .blockreviews-textarea {
  min-height: 240px;
}

.cancel-review-btn {
  flex-shrink: 1;
}

.send-review-btn {
  flex-shrink: 1;

  &.v-btn--disabled.v-btn--has-bg {
    color: var(--button-text-color) !important;
    background-color: var(--primary-button-bg-color) !important;
    opacity: 0.5;
  }
}

.comment {
  margin: 12px 0 0;
  color: var(--copyrights-color);

  &_error {
    color: var(--color-red);
  }
}

</style>

<style lang="scss">
@import "@/assets/style/mixins.scss";

.editor {
  @include vue-editor;
}
</style>
