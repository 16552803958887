<template>
  <div class="slider-container">
    <h4 class="slider-title">
      <slot name="title" />
    </h4>

    <p class="slider-subtitle">
      <slot name="subtitle" />
    </p>

    <div class="slider">
      <carousel
        v-model="currentSlideIndex"
        loop
        :autoplay-timeout="5000"
        :autoplay="isAutoPlay"
        autoplay-hover-pause
        navigation-enabled
        :per-page="1"
        pagination-active-color="var(--primary-icon-color)"
      >
        <slide
          v-for="image in images"
          :key="image"
        >
          <img
            width="100%"
            height="100%"
            :src="image"
            alt="slide"
            class="slider-image"
          >
        </slide>
      </carousel>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BRSlider',
  props: {
    isAutoPlay: {
      type: Boolean,
      required: true
    },
    images: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      currentSlideIndex: 0
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/style/mixins.scss";

.slider-image {
  border-radius: var(--border-radius-default);
  object-fit: cover;
}

.slider-title {
  font-size: var(--font-base);
  line-height: var(--line-height-standard);
}

.slider .slider-subtitle {
  @include subtitle;

  margin-bottom: 20px;

  @include breakpoint(medium) {
    margin-bottom: 36px;
  }
}

.slider {
  img {
    width: 100%;
    height: 344px;
    object-fit: contain;

    @include breakpoint(medium) {
      height: 398px;
    }
  }

  &::v-deep {
    .VueCarousel-dot {
      margin-top: 0 !important;
      border-radius: var(--border-radius-tile);
      padding: 8px !important;
      width: 8px !important;
      height: 8px !important;
    }

    .VueCarousel-navigation-button {
      top: calc(50% - 20px);
      outline: none;
      width: 16px;
      height: 16px;
      font-size: 0;
      background-image: url("/img/slider-arrow.svg");
      background-size: contain;
    }

    .VueCarousel-navigation-next {
      right: -25px;

      @include breakpoint-reverse(small) {
        right: -10px;
      }
    }

    .VueCarousel-navigation-prev {
      left: -25px;
      transform: translateY(-50%) translateX(-100%) rotateY(180deg);

      @include breakpoint-reverse(small) {
        left: -10px;
      }
    }

    .VueCarousel-dot-container {
      margin-top: 4px !important;
    }

    .blockreviews-editable-image {
      height: 100%;
    }
  }

  @include breakpoint(medium) {
    padding: 0 30px;
  }
}
</style>
