<template>
  <article class="bonus">
    <section class="information">
      <div>
        <div v-if="bonus.updated" class="updated">
          <p>Updated: <span>{{ bonus.updated }}</span></p>
        </div>
        <h3 v-if="bonus.title" class="bonus-title">
          {{ bonus.title }}
        </h3>

        <div v-if="website" class="website mobile">
          <a
            :href="website"
            class="link"
            target="_blank"
            rel="noopener noreferrer"
          >
            {{ website }}
          </a>
        </div>
        <div v-if="bonus.description" class="description">
          {{ bonus.description }}
        </div>
      </div>
      <div v-if="website" class="website desktop">
        <span>Website: </span>
        <a
          :href="website"
          class="link"
          target="_blank"
          rel="noopener noreferrer"
        >
          {{ website }}
        </a>
      </div>
    </section>
    <div class="divider-container">
      <div class="divider" />
    </div>

    <section class="code">
      <BRButton
        :height="$vuetify.breakpoint.xs ? '35' : '45'"
        class="bonus-code"
        color="var(--link-color)"
        dashed
        outlined
        @click="copyBonusCode(bonus.bonusCode)"
      >
        {{ bonus.bonusCode }}
      </BRButton>
      <div class="bonus-code-container">
        <BRButton
          v-if="bonus.claimBonusLink"
          :height="$vuetify.breakpoint.xs ? '35' : '45'"
          class="bonus-code"
          dark
          :href="bonus.claimBonusLink"
          target="_blank"
          rel="noopener noreferrer"
        >
          CLAIM BONUS
        </BRButton>
        <p class="bonus-description">
          {{ bonus.category }}
        </p>
      </div>
    </section>

    <BonusCodePopup :is-show-popup.sync="isBonusCodeCopied" />
  </article>
</template>

<script>
import copyBonus from '@/mixins/copyBonus'
import BonusCodePopup from '@/components/BonusCodePopup.vue'

export default {
  name: 'CasinoBonusCard',
  components: {
    BonusCodePopup
  },
  mixins: [copyBonus],
  props: {
    bonus: {
      type: Object,
      required: true,
      default: () => {
      }
    },
    website: {
      type: String,
      required: true,
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/style/mixins.scss";

.divider-container {
  margin: 0 12px;
  height: 34px;
  box-shadow: 0 10px var(--content-bg-color), 0 -10px var(--content-bg-color);
  background-color: var(--content-bg-color);

  @include breakpoint(medium) {
    margin: 21px 0;
    width: 25px;
    height: auto;
  }
}

.bonus {
  @include content;

  display: grid;
  grid-template-columns: 1fr 23px auto;
  margin-bottom: 15px;
  padding: 0;
  background-color: transparent;

  // box-shadow: var(--secondary-box-shadow);

  @include breakpoint-reverse(small) {
    grid-template-columns: 1fr;
  }
}

.information {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-top-left-radius: var(--border-radius-default);
  border-top-right-radius: var(--border-radius-default);
  padding: 20px 26px 8px;
  box-shadow: var(--tertiary-box-shadow);
  background-color: var(--content-bg-color);

  @include breakpoint(medium) {
    border-top-left-radius: var(--border-radius-default);
    border-top-right-radius: 0;
    border-bottom-left-radius: var(--border-radius-default);
    padding: 12px 25px;
    box-shadow: none;
  }
}

.bonus-description {
  display: none;
  margin-top: 12px;
  font-size: var(--font-xs);
  text-align: center;
  text-transform: uppercase;
  color: var(--secondary-text-color);

  @include breakpoint(medium) {
    display: block;
  }
}

.updated {
  display: flex;
  margin-bottom: 8px;
  font-style: italic;
  font-size: var(--font-sm);
  color: var(--secondary-text-color);
}

.bonus-title {
  margin-bottom: 8px;
  font-size: var(--font-h1);

  @include breakpoint(medium) {
    margin-bottom: 14px;
  }
}

.description {
  @include subtitle;
}

.website {
  margin-bottom: 16px;
  font-weight: var(--font-weight-bold);
  font-size: var(--font-sm);

  .link {
    color: var(--link-color);

    @include breakpoint(medium) {
      padding-left: 6px;
    }
  }
}

.divider {
  position: relative;
  margin: 0 12px;
  border: dashed 1px var(--border-color);
  border-image: url("/public/img/dashed-border.png");
  height: 100%;

  &::before,
  &::after {
    content: "";
    position: absolute;
    display: block;
    border: 5px solid transparent;
    border-top-color: var(--content-bg-color);
    border-right-color: var(--content-bg-color);
    border-radius: 100%;
    width: 32px;
    height: 32px;
  }

  &::before {
    right: -31px;
    top: -22px;
    transform: translate(-50%, -50%) rotate(135deg);

    @include breakpoint-reverse(small) {
      left: -12px;
      top: 0;
      transform: translate(-50%, -50%) rotate(45deg);
    }
  }

  &::after {
    right: -31px;
    bottom: -54px;
    transform: translate(-50%, -50%) rotate(-45deg);

    @include breakpoint-reverse(small) {
      right: -45px;
      top: 0;
      transform: translate(-50%, -50%) rotate(-135deg);
    }
  }

  @include breakpoint-reverse(small) {
    margin: 11px 0;
    border: dashed 1px var(--border-color);
    border-image: none;
    width: 100%;
    height: 1px;
  }
}

.code {
  display: flex;
  justify-content: center;
  border-bottom-right-radius: var(--border-radius-default);
  border-bottom-left-radius: var(--border-radius-default);
  padding: 0 26px 20px;
  box-shadow: 0 10px 12px rgba(52, 63, 116, 0.1), 0 -10px var(--content-bg-color);
  background-color: var(--content-bg-color);
  gap: 10px;

  .bonus-code {
    width: 48%;

    @include breakpoint(medium) {
      width: unset;
    }
  }

  ::v-deep .blockreviews-button.bonus-code {
    border-radius: var(--border-radius-small);

    @include breakpoint(medium) {
      border-radius: var(--border-radius-default);
    }
  }

  @include breakpoint(medium) {
    flex-direction: column;
    border-top-right-radius: var(--border-radius-default);
    border-bottom-right-radius: var(--border-radius-default);
    border-bottom-left-radius: 0;
    padding: 32px 34px 22px;
    box-shadow: none;
    gap: 16px;
  }
}

.delete-bonus-button {
  margin-right: -30px;
  width: 50px;

  @include breakpoint-reverse(small) {
    margin-top: 30px;
    margin-right: 0;
  }
}

.bonus-code-container {
  width: 48%;

  .bonus-code {
    width: 100%;
  }

  @include breakpoint(medium) {
    width: 100%;
  }
}

.mobile {
  @include breakpoint(medium) {
    display: none;
  }
}

.desktop {
  margin: 28px 0 0;

  @include breakpoint-reverse(small) {
    display: none;
  }
}
</style>
