<template>
  <section class="overview">
    <div v-if="casino.pross || casino.cons || totalWithdrawMethods" class="stats">
      <BRCardContainer v-if="casino.pross" class="card cons-pross">
        <h4 class="overview-title">
          Pross
        </h4>
        <div class="property-list">
          <div
            v-for="(pros, index) in casino.pross"
            :key="index"
            class="property"
          >
            <template v-if="pros.trim()">
              <BRIcon class="icon" height="14" icon="pross" />
              <div class="rich-text" v-html="pros" />
            </template>
          </div>
        </div>
      </BRCardContainer>
      <BRCardContainer v-if="casino.cons" class="card cons-pross">
        <h4 class="overview-title">
          Cons
        </h4>

        <div class="property-list">
          <div
            v-for="(con, index) in casino.cons"
            :key="index"
            class="property"
          >
            <template v-if="con.trim()">
              <BRIcon class="icon" height="14" icon="cons" />
              <div class="rich-text" v-html="con" />
            </template>
          </div>
        </div>
      </BRCardContainer>

      <BRCardContainer v-if="totalWithdrawMethods && totalWithdrawMethods.length" class="card">
        <h4 class="overview-title">
          Deposit methods
        </h4>

        <div class="payments-list">
          <BRContentChip
            v-for="depositMethod in totalDepositMethods"
            :key="depositMethod.id"
            :alt="depositMethod.name"
            :image-url="depositMethod.icon"
            class="payment"
          />
          <div
            v-if="isShowAllDepositMethodsButton"
            class="show-button"
            @click="toggleDepositMethodsView"
          >
            Show All
          </div>
          <div
            v-if="isShowHideDepositMethodsButton"
            class="show-button"
            @click="toggleDepositMethodsView"
          >
            Hide
          </div>
        </div>

        <div class="divider" />

        <h4 class="overview-title">
          Withdraw methods
        </h4>

        <div class="payments-list">
          <BRContentChip
            v-for="(payment, index) in totalWithdrawMethods"
            :key="index"
            :image-url="getPaymentIcon(payment)"
            class="payment"
          />
          <div
            v-if="isShowAllWithdrawMethodsButton"
            class="show-button"
            @click="toggleWithdrawMethodsView"
          >
            Show All
          </div>
          <div
            v-if="isShowHideWithdrawMethodsButton"
            class="show-button"
            @click="toggleWithdrawMethodsView"
          >
            Hide
          </div>
        </div>
      </BRCardContainer>
    </div>

    <BRCardContainer v-if="casino.descriptionTop || casino.sliderImages || casino.descriptionBottom" class="card">
      <BRRichTextRenderer
        v-if="casino.descriptionTop"
        :document="casino.descriptionTop"
        class="casino-description casino-description--top"
      />

      <BRSlider
        v-if="casino.sliderImages && casino.sliderImages.length"
        :images="casino.sliderImages"
        class="description-slider"
        is-auto-play
      />

      <BRRichTextRenderer
        v-if="casino.descriptionBottom"
        :document="casino.descriptionBottom"
        class="casino-description casino-description--bottom"
      />
    </BRCardContainer>

    <BRCardContainer v-if="casino.license || casino.customerSupport" class="card license">
      <div v-if="casino.license" class="license-main-info">
        <h4 class="overview-title">
          License
        </h4>
        <BRRichTextRenderer :document="casino.license" class="card-text" />
      </div>
      <div :class="['divider divider-vertical', {'hidden' : !casino.license}]" />
      <div class="add-info-container">
        <div v-if="casino.customerSupport && casino.customerSupport.length">
          <h4 class="overview-title">
            Customer support
          </h4>
          <ul v-if="casino.customerSupport && casino.customerSupport.length" class="support-list">
            <li v-for="supportItem in casino.customerSupport" :key="supportItem.id">
              <div class="label">
                <span>
                  {{ supportItem.name }}
                </span>
              </div>
            </li>
          </ul>
        </div>
        <div v-if="casino.devices && casino.devices.length">
          <h4 class="overview-title">
            Devices
          </h4>
          <ul class="support-list">
            <li v-for="device in casino.devices" :key="device.id">
              <div class="label">
                <span>
                  {{ device.name }}
                </span>
              </div>
            </li>
          </ul>
        </div>
        <div v-if="casino.yearEstablished">
          <h4 class="overview-title">
            Year Established
          </h4>
          <div class="label">
            <span>{{ casino.yearEstablished }}</span>
          </div>
        </div>
      </div>
    </BRCardContainer>

    <BRCardContainer v-if="casino.games && casino.games.length" class="card games">
      <div class="games-header">
        <h4 class="games-title">
          Game modes
        </h4>
        <BRButton
          v-if="isShowSeeAllGamesButton"
          class="see-all-games-button"
          text
          @click="toggleShowAllGames"
        >
          See all games
        </BRButton>
        <BRButton
          v-if="isShowHideGamesButton"
          class="hide-games-button"
          text
          @click="toggleShowAllGames"
        >
          Hide
        </BRButton>
      </div>
      <div class="games-list">
        <div v-for="game in totalGames" :key="game.id" class="game">
          <img
            :alt="game.name"
            :src="game.logo || '/img/default-image.webp'"
            height="130px"
            width="130px"
          >
          <p class="game-name">
            {{ game.name }}
          </p>
        </div>
      </div>
    </BRCardContainer>
    <BRCardContainer v-if="casino.countries && casino.countries.length" class="card">
      <h4 class="overview-title">
        Country that NOT supported
      </h4>

      <ul class="country-list">
        <li v-for="country in casino.countries" :key="country.id">
          <div class="label">
            <img
              :alt="country.name"
              :src="country.flag"
              height="15"
              width="20"
            >
            <span>
              {{ country.name }}
            </span>
          </div>
        </li>
      </ul>
    </BRCardContainer>
  </section>
</template>

<script>
import payments from '../../public/data/payments.json'
import devices from '../../public/data/devices.json'
import support from '../../public/data/support.json'
import gameCategories from '../../public/data/gameCategories.json'
import BRContentChip from '@/components/BRComponents/BRContentChip.vue'
import BRCardContainer from '@/components/BRComponents/BRCardContainer.vue'
import BRSlider from '@/components/BRComponents/BRSlider.vue'
import BRRichTextRenderer from '@/components/BRComponents/BRRichTextRenderer.vue'

export default {
  name: 'CasinoOverview',
  components: {
    BRContentChip,
    BRCardContainer,
    BRSlider,
    BRRichTextRenderer
  },
  props: {
    casino: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      payments,
      devices,
      support,
      gameCategories,
      isShowAllGames: false,
      isShowAllDepositMethods: false,
      isShowAllWithdrawMethods: false,
      previewItemsCount: 6
    }
  },
  computed: {
    totalGames() {
      return this.isShowAllGames
        ? this.casino.games
        : this.casino.games.slice(0, this.previewItemsCount)
    },
    totalDepositMethods() {
      return this.isShowAllDepositMethods
        ? this.casino.depositMethods
        : this.casino.depositMethods.slice(0, this.previewItemsCount)
    },
    totalWithdrawMethods() {
      return this.isShowAllWithdrawMethods
        ? this.casino.withdrawMethods
        : this.casino.withdrawMethods?.slice(0, this.previewItemsCount)
    },
    isShowSeeAllGamesButton() {
      return (
        this.casino.games?.length > this.previewItemsCount &&
        !this.isShowAllGames
      )
    },
    isShowHideGamesButton() {
      return (
        this.casino.games?.length >= this.previewItemsCount &&
        !this.isShowSeeAllGamesButton
      )
    },
    isShowAllDepositMethodsButton() {
      return this.casino.depositMethods?.length > this.previewItemsCount && !this.isShowAllDepositMethods
    },
    isShowAllWithdrawMethodsButton() {
      return this.casino.withdrawMethods?.length > this.previewItemsCount && !this.isShowAllWithdrawMethods
    },
    isShowHideDepositMethodsButton() {
      return this.casino.depositMethods?.length > this.previewItemsCount && !this.isShowAllDepositMethodsButton
    },
    isShowHideWithdrawMethodsButton() {
      return this.casino.withdrawMethods?.length > this.previewItemsCount && !this.isShowAllWithdrawMethodsButton
    }
  },
  methods: {
    toggleShowAllGames() {
      this.isShowAllGames = !this.isShowAllGames
    },
    toggleDepositMethodsView() {
      this.isShowAllDepositMethods = !this.isShowAllDepositMethods
    },
    toggleWithdrawMethodsView() {
      this.isShowAllWithdrawMethods = !this.isShowAllWithdrawMethods
    },
    getPaymentIcon(payment) {
      return payment.fields?.icon?.fields?.file?.url
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/style/mixins.scss";

.overview {
  @include container;
}

.card {
  --overview-card-shadow: 0 6px 8px rgba(52, 63, 116, 0.2);

  margin-bottom: 8px;
  box-shadow: var(--overview-card-shadow);

  @include breakpoint(medium) {
    margin-bottom: 14px;
    height: unset;
    box-shadow: none;
  }
}

.stats {
  display: grid;
  margin-bottom: 8px;
  gap: 8px;

  .property-list {
    .property {
      display: grid;
      align-items: center;
      grid-template-columns: 16px auto;
      margin-bottom: 12px;
      font-size: var(--font-base);
      gap: 18px;

      .icon {
        display: block;
        width: 16px;
      }
    }
  }

  .payments-list {
    display: flex;
    flex-wrap: wrap;
    margin-top: 15px;
    gap: 8px;

    .show-button {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 10px;
      height: 25px;
      font-weight: var(--font-weight-bold);
      font-size: var(--font-base);
      color: var(--primary-text-color);
      cursor: pointer;
    }

    ::v-deep .content-chip {
      width: 45px;
      height: 25px;
    }
  }

  @include breakpoint(medium) {
    display: flex;
    margin-bottom: 0;
    gap: 20px;
  }
}

.divider {
  margin-top: 19px;
  margin-bottom: 12px;
  height: 1px;
  background-color: var(--secondary-bg-color);
  opacity: 0.2;
}

.divider-vertical {
  @include breakpoint(medium) {
    margin: 0;
    width: 1px;
    height: auto;
  }
}

.card-text {
  margin-top: 15px;
  margin-bottom: 20px;
  font-size: var(--font-base);
  color: var(--secondary-text-color);
}

.overview-title {
  margin-bottom: 20px;
  font-size: var(--font-h4);
}

.casino-description {
  font-size: var(--font-base);
  color: var(--secondary-text-color);

  &--top {
    margin-bottom: 33px;
  }

  &::v-deep h1,
  &::v-deep h2,
  &::v-deep h3,
  &::v-deep h4,
  &::v-deep h5,
  &::v-deep h6 {
    margin-bottom: 20px;
    color: var(--text-color);
  }
}

.games {
  @include content;

  .games-header {
    display: flex;
    align-items: center;
    font-size: var(--font-h4);

    @include breakpoint-reverse(small) {
      justify-content: space-between;
    }
  }

  .see-all-games-button,
  .hide-games-button {
    margin-left: 10px;
  }

  .games-list {
    display: grid;
    align-items: center;
    grid-gap: 18px;
    grid-template-columns: repeat(auto-fill, minmax(130px, 1fr));
    margin-top: 15px;

    @include breakpoint(medium) {
      grid-gap: 16px;
    }
  }

  .game {
    display: grid;
    place-content: center;
    text-align: center;
    gap: 12px;

    img {
      border-radius: var(--border-radius-big);
      object-fit: cover;
    }
  }
}

.game-name {
  font-size: var(--font-base);
}

.description-slider {
  margin-bottom: 16px;

  @include breakpoint(medium) {
    margin-bottom: 48px;
  }
}

.description-list {
  display: grid;
  margin-top: 32px;
  padding: 0;
  gap: 14px;

  @include breakpoint(medium) {
    gap: 18px;
  }
}

.add-info-container {
  display: grid;
  flex: 0 0 350px;
  gap: 24px;
}

.support-list {
  display: flex;
  flex-wrap: wrap;
  gap: 6px;

  .label {
    height: 100%;
  }
}

.label {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: var(--border-radius-small);
  padding: 8px 22px;
  width: fit-content;
  font-size: var(--font-sm);
  text-align: center;
  color: var(--secondary-text-color);
  background-color: var(--tertiary-bg-color);
}

.license {
  display: flex;
  flex-direction: column;

  @include breakpoint(medium) {
    flex-direction: row;
    gap: 34px;
  }
}

.license-main-info {
  width: 100%;
  max-width: 468px;
}

.country-list {
  display: flex;
  column-gap: 4px;
  flex-wrap: wrap;
  row-gap: 6px;

  .label {
    display: flex;
    color: var(--text-color);
    gap: 4px;
  }
}

.slider-container ::v-deep .slider-subtitle {
  margin: 0;
}

.card .slider-subtitle {
  margin-bottom: 26px;

  @include breakpoint(medium) {
    margin-bottom: 36px;
  }
}

.hidden {
  display: none;
}
</style>
