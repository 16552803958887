<template>
  <section v-if="getAlternatives && getAlternatives.length" class="alternatives">
    <div class="casino-list">
      <CasinoVerticalCard
        v-for="alternative in getAlternatives"
        :key="alternative.id"
        :casino="alternative"
        class="casino"
      />
      <div v-if="isEdit" class="btn-container">
        <BRButton
          :height="$vuetify.breakpoint.xs ? '35' : '45'"
          :outlined="$vuetify.breakpoint.xs"
          class="add-btn"
          color="var(--primary-text-color)"
          width="100%"
        >
          Add Alternative
        </BRButton>
      </div>
    </div>
  </section>
  <div v-else class="no-content-block">
    <p>Nothing found</p>
  </div>
</template>

<script>
import image from '@/mixins/image'
import CasinoVerticalCard from '@/components/CasinoVerticalCard'

export default {
  name: 'CasinoAlternatives',
  components: {
    CasinoVerticalCard
  },
  mixins: [image],
  props: {
    casino: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      casinos: [],
      isLoading: true,
      select: '',
      loadCasinosParams: {
        pageNumber: 0,
        pageSize: 100,
        currencyName: '',
        keyword: '',
        sortBy: ''
      },
      searchTimer: null,
      isEdit: false
    }
  },
  computed: {
    searchKeyword() {
      return this.loadCasinosParams.keyword
    },
    getAlternatives() {
      return this.casino.alternatives
    }
  },
  watch: {
    searchKeyword() {
      if (this.searchTimer) {
        clearTimeout(this.searchTimer)
      }
      this.searchTimer = setTimeout(() => {
        this.resetCasinos()
        this.searchTimer = null
      }, 1200)
    }
  },
  methods: {
    searchFilter(item, queryText) {
      const textOne = item.title.toLowerCase()
      const searchText = queryText.toLowerCase()

      return textOne.includes(searchText)
    },
    resetCasinos() {
      this.casinos = []
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/style/mixins.scss";

.alternatives {
  @include container;

  .casino-list {
    display: grid;
    align-items: start;
    grid-gap: 20px;
    grid-template-columns: repeat(4, 1fr);

    @include breakpoint-reverse(medium) {
      grid-template-columns: 1fr;
    }
  }
}

.search-toolbar::v-deep.v-toolbar.v-sheet {
  padding: 20px 0;
  background-color: var(--content-bg-color);

  .v-select__slot {
    background-color: var(--content-bg-color);

    input,
    input::placeholder,
    .v-icon,
    .v-label,
    .v-select__selections {
      color: var(--text-color);
    }
  }

  .v-text-field > .v-input__control > .v-input__slot::before {
    border-color: var(--border-color);
  }
}

.v-dialog__content ~ .v-autocomplete__content .v-select-list {
  background-color: var(--content-bg-color);

  &::v-deep .v-list-item {
    color: var(--text-color);
  }
}

.btn-container {
  display: flex;
  justify-content: center;
  width: 100%;

  @include breakpoint(medium) {
    align-items: center;
    border-radius: var(--border-radius-default);
    padding: 0 35px;
    height: 100%;
    background-color: var(--content-bg-color);
  }
}

.add-btn {
  text-transform: uppercase;

  @include breakpoint(medium) {
    text-transform: none;
    color: var(--contrast-text-color);
  }
}

.no-content-block {
  @include container;

  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
}
</style>
