<template>
  <!-- TODO: Loader -->
  <section class="casino-header">
    <div class="main-info-container">
      <div class="main-info">
        <img
          :src="getCasinoLogo"
          alt="Casino image"
          class="casino-image"
          height="50"
          width="50"
        >

        <div
          class="description"
        >
          <template v-if="mock.country">
            <p v-if="mock.country" class="acceptance">
              <!--TODO: need flag icons-->
              <BRIcon
                :icon="mock.icon"
                class="icon icon-country"
                height="10"
                width="10"
              />
              <span class="acceptance-country">Accept players from {{ mock.country }}</span>
            </p>
          </template>
          <h1 class="casino-title">
            {{ casinoData.name }}
          </h1>
          <a
            v-if="casinoData.website"
            class="casino-subtitle"
            :href="casinoData.website"
            target="_blank"
            rel="noreferrer noopener"
          >
            {{ casinoData.website }}
          </a>
        </div>
      </div>
      <button
        aria-label="Add to bookmarks"
        class="bookmark"
        @click="toggleCasinoSubscription"
      >
        <BRIcon
          :class="{ 'bookmark-marked': isBookmarked }"
          :fill-color=" isBookmarked ? 'var(--primary-icon-color)' : 'none'"
          height="20"
          :stroke="isBookmarked ? 'transparent' : 'var(--default-icon-color)'"
          width="15"
          icon="bookmark"
        />
      </button>
    </div>

    <div class="rating">
      <h3 class="text">
        Total Rating:
      </h3>
      <div class="rating-value-wrapper">
        <div>
          <div class="amount" :style="{ color: getRatingColor(casinoData.totalRating) }">
            {{ casinoData.totalRating }}
          </div>
          <span class="of">/5</span>
        </div>
        <BRIcon
          :height="$vuetify.breakpoint.xs ? '30' : '40'"
          :width="$vuetify.breakpoint.xs ? '26' : '35'"
          class="icon-rating"
          icon="rating"
          :style="{ color: getRatingColor(casinoData.totalRating) }"
        />
      </div>
    </div>

    <div class="bonus-container">
      <h3 v-if="casinoData.bonusCode" class="bonus-subtitle">
        Bonus code:
      </h3>
      <div class="bonus-buttons">
        <BRButton
          v-if="casinoData.bonusCode"
          :height="$vuetify.breakpoint.xs ? '35' : '45'"
          :width="$vuetify.breakpoint.xs ? '48%' : '123'"
          color="var(--link-color)"
          dashed
          outlined
          @click="copyBonusCode(casinoData.bonusCode)"
        >
          {{ casinoData.bonusCode }}
        </BRButton>
        <BRButton
          v-if="casinoData.claimBonusLink"
          :height="$vuetify.breakpoint.xs ? '35' : '45'"
          :href="casinoData.claimBonusLink"
          :width="$vuetify.breakpoint.xs ? '48%' : '150'"
          dark
        >
          CLAIM BONUS
        </BRButton>
      </div>
    </div>
    <BonusCodePopup :is-show-popup.sync="isBonusCodeCopied" />
  </section>
</template>

<script>
import { mapGetters } from 'vuex'
import images from '@/mixins/image'
import copyBonus from '@/mixins/copyBonus'
import ratingColor from '@/mixins/ratingColor'
import bookmarkCasino from '@/mixins/bookmarkCasino'
import BonusCodePopup from '@/components/BonusCodePopup'

export default {
  name: 'CasinoHeader',
  components: {
    BonusCodePopup
  },
  mixins: [images, copyBonus, bookmarkCasino, ratingColor],
  props: {
    casino: {
      type: Object,
      required: true,
      default: () => {
      }
    },
    isBookmarked: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      casinoData: this.casino,
      // TODO: need real data
      mock: {
        country: 'Poland',
        icon: 'poland'
      }
    }
  },
  computed: {
    ...mapGetters(['getUserDB', 'getUserLocation']),
    isCountryAccepted() {
      return !this.casinoData.countries ||
        !this.casinoData.countries.find(({ fields }) => fields.shortName === this.getUserLocation)
    },
    getCasinoLogo() {
      return this.casinoData.logo || '/img/default-image.webp'
    }
  },
  watch: {
    casino() {
      this.casinoData = this.casino
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/style/mixins.scss";

.casino-header {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 24px;
}

.casino-image {
  border-radius: 50%;
  object-fit: cover;

  @include breakpoint(medium) {
    width: 80px;
    height: 80px;
  }
}

.acceptance {
  display: flex;
  margin-bottom: 6px;
  font-weight: var(--font-weight-bold);
  font-size: var(--font-xs);
  gap: 6px;
}

.casino-title {
  display: block;
  margin-right: 15px;
  margin-bottom: 6px;
  font-size: var(--font-h1);
}

.casino-subtitle {
  font-weight: var(--font-weight-bold);
  font-size: var(--font-base);
  color: var(--link-color);
}

.main-info {
  display: flex;
  gap: 16px;

  &-container {
    display: flex;
    flex: 1 0 max-content;
    justify-content: space-between;
    gap: 18px;

    @include breakpoint(medium) {
      justify-content: flex-start;
    }
  }

  @include breakpoint(medium) {
    align-items: center;
    justify-content: flex-start;
    gap: 30px;
  }
}

.icon-rating {
  color: var(--primary-icon-color);
}

.rating {
  display: flex;
  flex-shrink: 0;
  flex-basis: 100%;
  flex-direction: row;
  align-items: center;
  gap: 10px;

  .text {
    font-size: var(--font-base);
  }

  .rating-value-wrapper {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .amount {
    display: inline-block;
    font-weight: var(--font-weight-bold);
    font-size: var(--font-h1);
    color: var(--primary-text-color);
  }

  .of {
    font-size: var(--font-base);
  }

  .rate-star {
    margin-bottom: 2px;
  }

  @include breakpoint(medium) {
    flex-basis: revert;
    flex-direction: column;
  }
}

.bonus-buttons {
  display: flex;
  justify-content: flex-start;
  gap: 10px;

  .blockreviews-button {
    flex-grow: 1;
  }

  @include breakpoint(medium) {
    justify-content: flex-start;
    gap: 15px;
  }
}

.select-currency::v-deep {
  margin-top: 20px;

  .v-label,
  .v-input__append-inner .v-icon {
    color: var(--text-color);
  }
}

.v-dialog__content ~ .v-autocomplete__content .v-select-list {
  background-color: var(--content-bg-color);

  &::v-deep .v-list-item {
    color: var(--text-color);
  }
}

.bonus-subtitle {
  display: none;
  margin-bottom: 10px;
  font-size: var(--font-base);

  @include breakpoint(medium) {
    display: block;
  }
}

.bonus-container {
  flex-grow: 1;
  align-self: center;
  padding-top: 2px;

  @include breakpoint(medium) {
    flex-grow: 0;
    align-self: flex-start;
    padding-top: 0;
  }
}

.icon-country {
  display: none;
}
</style>
