<template>
  <section class="bonuses">
    <template v-if="casino.bonuses && casino.bonuses.length">
      <BRBonusCard
        v-for="bonus in casino.bonuses"
        :key="bonus.title"
        :bonus="bonus"
        :website="casino.website"
        class="bonus"
      />
    </template>
    <template v-else>
      <p class="no-bonuses">
        No bonuses available
      </p>
    </template>
  </section>
</template>

<script>
import BRBonusCard from '@/components/BRComponents/BRBonusCard.vue'

export default {
  name: 'CasinoBonuses',
  components: { BRBonusCard },
  props: {
    casino: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/style/mixins.scss";

.bonuses {
  @include container;

  display: grid;
}

.no-bonuses {
  @include container;

  display: flex;
  align-items: center;
  height: 200px;
  text-align: center;
}
</style>
